import React from 'react'
import { PropTypes } from 'prop-types'
import FontIcon from 'components/FontIcon'
import Platform from 'lib/Platform'
import { useNavigation } from '@react-navigation/native'
import Link from 'components/Link'
import { space, fontColor } from 'styles/constants'
import {
  StyleSheet,
  View
} from 'react-native'

const styles = StyleSheet.create({
  link: {
    paddingLeft: space[0.5],
    paddingRight: space[0.5],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
})

const Help = props => {

  const navigation = useNavigation()

  const {
    // FIXME: stop passing help object, but pass title and body
    help,
    title,
    body,
    size='sm',
    iconColor,
    onPress,
    style,
    children,
  } = props

  const handlePress = ()=> {
    onPress?.()
    navigation.navigate('HelpModal', {
      title: title ? title : help.title,
      body: body ? body : help.body
    })
  }

  if(!help && !title && !body) return null

  return (
    <View style={style}>
      <Link onPress={handlePress} style={styles.link}>
        {children}
        <FontIcon
          size={size}
          name='circle-question-light'
          color={iconColor || fontColor.label}
        />
      </Link>
    </View>
  )

}

Help.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  // DEPRECATED: use title and body instead
  help: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  size: PropTypes.string,
}

export default Help
