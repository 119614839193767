import React from 'react'
import View from 'components/View'
// original smartbanner is stale and uses outdated dependencies
import ReactAppBanner from '@/lib/react-smartbanner'
import '@/lib/react-smartbanner/main.css'
import { t } from '@/i18n'

const AppBanner = ()=> {

  const title = t('appName') + ' - ' + t('sales.short')
  const storeText = {
    ios: t('labels.app.getOnAppStore'),
    android: t('labels.app.getOnGooglePlay'),
  }
  const price = {
    android: t('labels.app.free'),
    ios: t('labels.app.free'),
  }
  const button = t('labels.app.open')

  // height: 1 element is required for web.
  // When the ReactAppBanner was hidden, all the scroll stopped working.
  // Adding 1 height element fixed the issue, but I need to find the root cause and fix that.
  return (
    <>
      <View style={{height: 1}} />
      <ReactAppBanner
        //force='android'
        title={title}
        button={button}
        position='top'
        storeText={storeText}
        price={price}
        daysHidden={14}
        daysReminder={14}
      />
    </>
  )

}

export default AppBanner
